import React, { useState, useEffect } from 'react';
import './Testimonials.css'; // Make sure the path is correct based on your file structure

const testimonials = [
    {
        id: 1,
        title: 'PlayStation 5',
        amount: '$400',
        message: `"It was unbelievable how fast my friends funded my PlayStation gift. I had the best birthday ever, playing games all night!"`,
        imgUrl: '/images/Testimonials_Playstation.jpg', // Update the path to your image
    },
    {
        id: 2,
        title: 'Acoustic Guitar',
        amount: '$250',
        message: `"Receiving a guitar was a dream come true. I shared my Wishlist, and before I knew it, I was strumming away. Thank you!"`,
        imgUrl: '/images/Testimonials_Guitar.jpg', // Update the path to your image
    },
    {
        id: 3,
        title: 'Mountain Bike',
        amount: '$600',
        message: `"I never thought I could own a professional mountain bike, but my friends and family came together and made it happen through Wishlist. I'm beyond grateful!"`,
        imgUrl: '/images/Testimonials_Bike.jpg', // Update the path to your image
    },
];

const Testimonials = () => {
    const [current, setCurrent] = useState(0);

    // Change testimonials every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent((prev) => (prev + 1) % testimonials.length);
        }, 5000);
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const { title, amount, message, imgUrl } = testimonials[current];

    return (
        <section id="testimonials" className="testimonials">
            <h2 className="section-title">Hear from Our Happy Funders</h2>
            <div className="testimonial-content">
                <div className="testimonial-image-wrapper">
                    <img src={imgUrl} alt={title} className="testimonial-image" />
                    <span className="testimonial-amount">{amount}</span>
                </div>
                <div className="testimonial-info">
                    <h3>{title}</h3>
                    <img src="/images/5star.gif" alt="5 stars" className="testimonial-stars" /> {/* Replace with your stars image path */}
                    <p className="testimonial-message">{message}</p>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
