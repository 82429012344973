import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // <-- Import this
axios.defaults.withCredentials = true;

const Login = () => {
  const [userData, setUserData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');  // <-- Error state
  const { email, password } = userData;

  const navigate = useNavigate();  // <-- Use the hook here

  const onChange = e => setUserData({ ...userData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    setError('');  // <-- Clear any previous errors
    const loginUser = {
      email,
      password
    };
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify(loginUser);
      await axios.post('http://157.90.247.182:3001/api/users/login', body, config);
      console.log('User logged in');
      navigate('/dashboard');  // <-- Navigate to dashboard after successful login
    } catch (error) {
      console.error(error.response.data);
      setError(error.response.data);  // <-- Set the error message based on the API response
    }
  };

  return (
    <div className="auth-form-container">
      {error && <div className="error">{error}</div>} 
      <h1>Login</h1>
      <form onSubmit={e => onSubmit(e)}>
        <input 
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={e => onChange(e)}
            required 
        />
        <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={e => onChange(e)}
            required
        />
        <input type="submit" value="Login" />
      </form>
      <div className="separator"></div>
    </div>
   );   
};

export default Login;
