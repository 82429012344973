import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
    const heroStyles = {
        backgroundImage: `url('/images/Wishlist-HeroSection_1_clean.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        //height: '100vh',  // This makes the section as tall as the viewport
        color: 'black'  // Assuming the text color is white for visibility against a darker image
    };

    return (
        <section id="herosection" style={heroStyles} className="hero-section">
            <div className="hero-title">
                <h1>Wishlist</h1>
                <p className="tagline">Your perfect gift is just a wish away</p>
            </div>
            <div className="cta-container">
                <p>Start creating your wishlist now</p>
                <button className="btn btn-primary">Create Your Wish</button>
            </div>
        </section>
    );
};

export default HeroSection;
