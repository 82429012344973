import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create a context
const AuthContext = createContext();

axios.defaults.withCredentials = true;

// Export the hook to use the context
export const useAuth = () => useContext(AuthContext);

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to check authentication status
  const checkAuthStatus = async () => {
    try {
      const response = await axios.get('http://157.90.247.182:3001/api/users/checkAuth');
      console.log(response.data); // Add this line to see the response
      setIsAuthenticated(response.data.isAuthenticated);
    } catch (error) {
      console.error('Error checking authentication status:', error);
      setIsAuthenticated(false);
    }
  };


  // Check auth status on component mount
  useEffect(() => {
    checkAuthStatus();
  }, []);

  // Value provided by the context
  const value = { isAuthenticated, checkAuthStatus };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
