import React from 'react';

const Footer = () => {
    return (
        <footer id="footer" className="bg-light text-center text-lg-start">
            {/* Grid container */}
            <div className="container p-4">
                {/*Grid row*/}
                <div className="row">
                    {/*Grid column*/}
                    <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
                        <h5 className="text-uppercase">Funded Gifts</h5>
                        <p>
                            Bringing joy and perfect gifts to everyone, one wish at a time.
                        </p>
                    </div>
                    {/*Grid column*/}

                    {/*Grid column*/}
                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 className="text-uppercase">Company</h5>

                        <ul className="list-unstyled mb-0">
                            <li>
                                <a href="#!" className="text-dark">About Us</a>
                            </li>
                            <li>
                                <a href="#!" className="text-dark">Blog</a>
                            </li>
                            <li>
                                <a href="#!" className="text-dark">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    {/*Grid column*/}

                    {/*Grid column*/}
                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 className="text-uppercase">Support</h5>

                        <ul className="list-unstyled">
                            <li>
                                <a href="#!" className="text-dark">FAQ</a>
                            </li>
                            <li>
                                <a href="#!" className="text-dark">Support Center</a>
                            </li>
                            <li>
                                <a href="#!" className="text-dark">Terms of Service</a>
                            </li>
                            <li>
                                <a href="#!" className="text-dark">Privacy Policy</a>
                            </li>
                        </ul>
                    </div>
                    {/*Grid column*/}
                </div>
                {/*Grid row*/}
            </div>
            {/* Grid container */}

            {/* Copyright */}
            <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                © 2023 Funded.Gifts
            </div>
        </footer>
    );
};

export default Footer;
