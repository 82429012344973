import React from 'react';
import './FAQ.css'; // Make sure the path is correct

const FAQ = () => {
    return (
        <div id="faq-section" className="faq-section py-5">
            <div className="container">
                <h2 className="text-center mb-4">Frequently Asked Questions</h2>
                <div className="accordion" id="faqAccordion">
                    {/* Array of questions and answers. You can add as many as you want by following the same structure */}
                    {[{
                        question: "What is Wishlist and how does it work?",
                        answer: "Wishlist is your gateway to receiving the perfect gift! It's a unique space where you can set up a wish for any item from any online store, share it with your network, and watch as friends, family, and even kind strangers contribute to your dream gift. Just start a wish, share the link, and we'll handle the logistics, ensuring your gift reaches you once fully funded. It's all about bringing people together to make your special moments unforgettable.",
                        id: "faq1"
                    }, {
                        question: "Is it free to create a Wish on Wishlist?",
                        answer: "Absolutely! Creating a wish is entirely free. We aim to keep your gifting experience smooth and joyful, which is why we only apply a minimal fee to cover the payment gateway charges – this is roughly 5% of the contribution, ensuring secure and hassle-free transactions. Occasionally, there might be additional delivery costs, but we always strive for transparency and will inform you if that's the case. Our goal is to make your wishes come true without hidden costs!",
                        id: "faq2"
                    },  {
                        question: "How do I share my wishlist with friends and family?",
                        answer: "Sharing the joy is super easy! After crafting your perfect wish, you'll find a 'Share' button on your action page. A click opens up a world of possibilities, letting you spread the word through email, social media, or any messaging app. Whether it's Facebook, WhatsApp, Instagram, or an old-fashioned text message, we've got you covered. The more you share, the closer you are to making your wish come true!",
                        id: "faq3"
                    },  {
                        question: "Can people contribute anonymously to my wishlist?",
                        answer: "Of course! We understand the joy of giving, sometimes, comes from staying in the shadows. Contributors can opt for anonymity during the payment process, and we respect that choice by not storing any personal data unless they wish to share it. At Wishlist, everyone can be a secret Santa!",
                        id: "faq4"
                    },  {
                        question: "What happens if I don’t reach my funding goal?",
                        answer: "No wish is left behind! If your goal isn't met, you're not out of options. You might top up the remaining amount, or perhaps alter your wish to match the funds gathered. As an alternative, you could opt for a Wishlist credit or choose from a variety of gift cards from numerous beloved stores. While we don't offer cash refunds, we're dedicated to ensuring every penny contributed brings you happiness in one form or another.",
                        id: "faq5"
                    },  {
                        question: "How do I receive my gift once the wishlist is fully funded?",
                        answer: "The magic happens seamlessly! Once funded, our dedicated team swings into action, finalizing the details and placing the order on your behalf. We keep you in the loop at each step and forward any tracking information directly to your email. Rest assured, your gift is on its way, and you can monitor its journey through your Wishlist account.",
                        id: "faq6"
                    },  {
                        question: "Is there a limit to the number of items I can add to my wishlist?",
                        answer: "We believe in the 'one wish, one item' philosophy to keep things clear and focused. However, the sky's the limit when it comes to creating multiple wishes! Feel free to start as many actions as you like, each with its unique item to cherish.",
                        id: "faq7"
                    },  {
                        question: "Can I return or exchange a gift funded through Wishlist?",
                        answer: "Absolutely! We adhere to the shop’s return policies. If the order was in your name, you'd handle returns directly with the retailer, using the provided documents. For orders we place, you'll find a handy 'refund/exchange' option in your funded actions. Our team assists with the process, ensuring you only need to attach the return slip we provide to the package and send it off. Simple and hassle-free!",
                        id: "faq8"
                    },  {
                        question: "How secure are the payments made through the platform?",
                        answer: "Security is our priority. We partner exclusively with Stripe and PayPal, global leaders in secure payment technologies. Your checkout process is safeguarded with advanced SSL encryption, and we maintain a strict policy of not storing sensitive financial details. As Wishlist, we do not store any kind of financial and bank information in our database. With Wishlist, your transactions are in safe hands.",
                        id: "faq9"
                    },  {
                        question: "Do you offer customer support in case I run into issues?",
                        answer: "Certainly! Our customer care team, spanning various time zones, is here to assist. While we're growing and can't yet offer round-the-clock phone support, our responsive email service bridges the gap. We're prompt to reply, and if a situation calls for it, we won't hesitate to give you a personal callback. Your smooth Wishlist journey is our commitment.",
                        id: "faq10"
                    } /* more items here */].map((item, index) => (
<div className="accordion-item" key={item.id}>
                            <h2 className="accordion-header" id={`heading${item.id}`}>
                                <button 
                                    className="accordion-button collapsed"  // Notice the 'collapsed' class here
                                    type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target={`#collapse${item.id}`} 
                                    aria-expanded="false"  // Set to 'false' here
                                    aria-controls={`collapse${item.id}`}>
                                    {item.question}
                                </button>
                            </h2>
                            <div 
                                id={`collapse${item.id}`} 
                                className="accordion-collapse collapse"  // Removed 'show' class here
                                aria-labelledby={`heading${item.id}`} 
                                data-bs-parent="#faqAccordion">
                                <div className="accordion-body">
                                    {item.answer}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;
