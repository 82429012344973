import React from 'react';
import './HowItWorks.css'; // Make sure the path is correct based on your file structure

const HowItWorks = () => {
    return (
        <section id="how-it-works" className="how-it-works">
            <h2 className="section-title">How It Works</h2>
            <div className="steps-container">
                {/* Step 1 */}
                <div className="step">
                    <img src="/images/HowItWorks_Step1.jpg" alt="Step 1" className="step-image" /> {/* Adjust the src based on your actual image names */}
                    <h3>Set Up Your Wishlist</h3>
                    <p>Create your wishlist by simply pasting the URL of the product you desire from any online store. Wishlist fetches the product details, including pictures, descriptions, and price.</p>
                </div>
                {/* Step 2 */}
                <div className="step">
                    <img src="/images/HowItWorks_Step2.jpg" alt="Step 2" className="step-image" /> {/* Adjust the src based on your actual image names */}
                    <h3>Share Your Wishlist</h3>
                    <p>Spread the word about your wishlist! Share it directly with friends and family through social media, email, messaging apps, or any platform of your choice.</p>
                </div>
                {/* Step 3 */}
                <div className="step">
                    <img src="/images/HowItWorks_Step3.jpg" alt="Step 3" className="step-image" /> {/* Adjust the src based on your actual image names */}
                    <h3>Watch Your Gift Get Funded</h3>
                    <p>Track the funding progress of your gift in real-time. Contributors can send money easily via PayPal or credit card, with options to leave messages and choose whether to stay anonymous.</p>
                </div>
                {/* Step 4 */}
                <div className="step">
                    <img src="/images/HowItWorks_Step4.jpg" alt="Step 4" className="step-image" /> {/* Adjust the src based on your actual image names */}
                    <h3>Receive Your Perfect Gift</h3>
                    <p>Once your gift is fully funded, Wishlist takes care of the rest. We order your gift to ensure a timely delivery directly to your doorstep.</p>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
