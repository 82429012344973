import React, { useState } from 'react';
import Register from '../Register/Register';
import Login from '../Login/Login';
import './AuthPage.css';

function AuthPage() {
  const [showLogin, setShowLogin] = useState(true);

  return (
    <div className="auth-page">
        <div className="row">
        <div className="col-md-6">
            <h2>Welcome to Wishlist!</h2>
            <p>
            {showLogin
                ? "Don't have an account yet? Join us and create your own Wishlist!"
                : 'Already have an account? Login to access your Wishlist!'}
            </p>
            <button 
            className="auth-form" 
            onClick={() => setShowLogin(!showLogin)}
            >
            {showLogin ? 'Register' : 'Login'}
            </button>
        </div>

        <div className="col-md-6">
            {showLogin ? <Login /> : <Register />}
            {/* Social login buttons */}
            <div className="social-login">
                <button className="btn btn-google">Login with Google</button>
                <button className="btn btn-facebook">Login with Facebook</button>
            {/* ... other social login buttons */}
            </div>
        </div>
        </div>
    </div>
  );
}

export default AuthPage;
