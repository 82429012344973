import React from 'react';
import './Dashboard.css';

function Dashboard() {
  return (
    <div className="dashboard">
      <h2>Welcome to your Dashboard</h2>
      <p>This is where you'll see all your wishlist items, notifications, and more.</p>
      {/* Add more dashboard-related content here */}
    </div>
  );
}

export default Dashboard;
