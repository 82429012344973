import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // Import Navigate
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Header from './components/Header/Header';
import AuthPage from './components/Authorization/AuthPage';
import Dashboard from './components/Dashboard/Dashboard';
import { AuthProvider } from './contexts/AuthContext';
import { useAuth } from './contexts/AuthContext';
import HeroSection from './components/HeroSection/HeroSection';
import HowItWorks from './components/HowItWorks/HowItWorks';
import Testimonials from './components/Testimonials/Testimonials';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
// import other components...

//import logo from './logo.svg';
import './App.css';

function HomePage() {
  return (
    <>
      <HeroSection />
      <section id="how-it-works">
        <HowItWorks />
      </section>
      <section id="testimonials">
        <Testimonials />
      </section>
      <section id="FAQ">
        <FAQ />
      </section>
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <ActualAppContent />
    </AuthProvider>
  );
}

function ActualAppContent() {
  const { isAuthenticated, checkAuthStatus } = useAuth();  // <-- Use the hook here

  useEffect(() => {
    // This will check the auth status when the component mounts
    checkAuthStatus();
  }, [checkAuthStatus]);

  return (
    <Router>
      <div className="App">
        <Header />
        <div className="spacer"></div>
        <main className="container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/dashboard" element={ isAuthenticated ? <Dashboard /> : <Navigate to="/auth" /> } />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
