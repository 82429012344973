import React from 'react';

const Header = () => {
    return (
        <header className="header">
            <div className="container header-inner">
                <div className="logo">Wishlist</div>
                <nav>
                    <ul>
                        <li><a href="#how-it-works">How It Works</a></li>
                        <li><a href="#testimonials">Testimonials</a></li>
                        <li><a href="/auth">Sign Up/Login</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
